import React, { useState } from "react"
import axios from 'axios';

import Emoji from '../helpers/Emoji'

import * as SignUpStyles from "../SignUp/signup.module.scss"
import { Link } from "gatsby";

import { useForm } from "react-hook-form";
import { navigate } from "gatsby"
import Spinner from "../elements/Spinner";

import queryString from 'query-string'
import { isBrowser } from "../helpers/helpers"

import Password from "../elements/PasswordField";

const LoginForm = (props) => {

	const getUrlParams = () => {
		var vars = [];
		var hash;
		var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
		for (var i = 0; i < hashes.length; i++) {
			hash = hashes[i].split('=');
			// vars.push(hash[0]);
			vars[hash[0]] = decodeURIComponent(hash[1]);
		}
		return vars;
	}	

	const [urlParams, setUrlParams] = useState(isBrowser() ? getUrlParams() : false)
	
	const [isSubmitting, setIsSubmitting] = useState(false);
  const [loginError, setLoginError] = useState('');
	
  const { register, handleSubmit, formState } = useForm({
		mode: 'onBlur',
    defaultValues: {
			grant_type: "password",
      client_id: "mercury",
    },
  });
  const { errors } = formState;
	


  const onSubmit = data => {
		
		//Getting all url paramaters and joining them
		const loginParams = urlParams ? `&${Object.entries(urlParams).join('&').replace(/,/g, '=')}` : '';
		const appLocation = (urlParams && urlParams.redirect) ? urlParams.redirect : '#';

		setLoginError('');
    
    setIsSubmitting(true);

    axios.post(`${process.env.GATSBY_MERCURL}/Token`, new URLSearchParams(data).toString())
    .then((response) => {
      if (data.loginRememberMe) {
        const tokenJson = JSON.stringify({accessToken: response.data.access_token, refreshToken: response.data.refresh_token, isLegacyToken: true});
        localStorage.setItem('ls.authorizationData', tokenJson);
      }
      setIsSubmitting(false);

			//if you want to be explicit with URL Params, list as ${urlParams.reason}&${urlParams.redirect}&${urlParams.inviteToken}... 
      window.location.href = `/App${appLocation}?${new URLSearchParams(response.data).toString()}${loginParams}`;
    })
    .catch(function (error) {
      setIsSubmitting(false);
      // A non HTTP 300 response was returned, check for specific error messaged
      // we want to show to users, else show a generic error message
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data === 'Invalid username or password.'
      ) {
        setLoginError('Your username or password were incorrect.')
      } else {
        setLoginError('Sorry there was a problem logging you in. Please try again.')
      }
    });
  };
  const onError = (errors, e) => {
    setIsSubmitting(false);
    setLoginError('Sorry something went wrong logging you in.')
  }
  return (
    <section className={SignUpStyles.signUp}>
      <div className={SignUpStyles.innerDiv}>
        <div className="component">
          <h1>Log In</h1>
          <div className={SignUpStyles.subText}>Enter your username and password to get access to Loaded <Emoji symbol="💪" label="Muscles" /> </div>

          <form id="login" onSubmit={handleSubmit(onSubmit, onError)} action="/login" method="POST">
            <input type="hidden" {...register("grant_type")} />
            <input type="hidden" {...register("client_id")} />
            <div className={SignUpStyles.emailForm}>
              <div className={SignUpStyles.formDiv}>
                <div className={SignUpStyles.inputWrap}>
                  <label htmlFor="username">Username 
                    <input type="text" id="username" placeholder={`Username`} autoComplete="username" className="text-input" {...register("username", { required: true, pattern: "[^@\s]+@[^@\s]+\.[^@\s]+" } )} />
                  </label>
                  {(errors !== undefined && errors.email) && <span className={SignUpStyles.hasErrors}>A valid email address is required</span>}
                </div>
                <div className={SignUpStyles.inputWrap}>
                  <label htmlFor="current-password">Password 
                    <Password id="current-password" autoComplete="current-password" register={register("password", { required: true})} error={errors.password} />
                  </label>
                </div>
                <div className={`${SignUpStyles.inputWrap}`}>
                  <label className={SignUpStyles.inputWrapCheckbox}><input className={SignUpStyles.inputCheckbox} type="checkbox" {...register("loginRememberMe")} id="loginRememberMe" /> Remember Me</label>
                </div>


                {loginError && <div className={`${SignUpStyles.errorMessage}`}>
                  {loginError}
                </div>}


                <div className={`${SignUpStyles.inputWrap} `}>
                  <button type="submit" className={SignUpStyles.button}><span className={SignUpStyles.buttonInner}>{isSubmitting && <Spinner />} Login &rarr;</span></button>
                </div>

                <div className={SignUpStyles.forgot}>
                  <Link to="/login/ForgotPassword">Forgotten password? Click here to reset.</Link>
                </div>

              </div>
            </div>
          </form>
          <div className={SignUpStyles.disclaimer}>
            By creating a Loaded account you agree to the <Link to="/end-user-licence-agreement">End User Licence Agreement</Link> and <Link to="/privacy-policy">Privacy Policy.</Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LoginForm;
