import React from "react"
import Base from "../../components/templates/Base"

import LoginForm from "../../components/Login/Login"

const LoginPage = ({ location }) => {
	return (
		<Base>
			<LoginForm location={location} />
		</Base>
	)
}

export default LoginPage
